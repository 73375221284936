import React from "react";
import loadable from "@loadable/component";
import cn from 'classnames'
import * as widont from "widont";
import PropTypes from "prop-types";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import StructuredTextRenderer from "../../../StructuredTextRenderer/StructuredTextRenderer";
import * as StandardHeroStyling from "./StandardHero.module.scss";
import colors from "lib/colors";
const InpageNav = loadable(() => import('../../../InpageNav/InpageNav'));

const StandardHero = ({ block, miniHeadline, smallFontSize }) => {
  const backgroundColor = block.backgroundColor.hex;
  const heroTitle = block.heroTitle;
  const heroImage = block.heroImage;
  const subtitleText = block.subtitleText;
  const text = block.text;
  const isActiveInPageNav = block.activateInPageNavigation;
  const isInPageNavDropdown = block.inPageNavigationAsDropdown;
  const hideBgImgOnMobile = block.hideBackgroundImageOnMobile;
  const inPageTrackingName = block.inPageTrackingName;
  const inPageLinks = block.inpagelinks;
  const hasDarkBackground = colors.isDarkColor(backgroundColor);

  return (
    <section
      className={`hero-container ${StandardHeroStyling.standardHero}`}
      style={{ backgroundColor: `${backgroundColor}` }}
    >
      <Section className={cn(StandardHeroStyling.heroTop, {
        [StandardHeroStyling.heroTop__showImageMobile]: !hideBgImgOnMobile
      })}>
        <div className="row">
          <div
            className={
              hasDarkBackground
                ? `col m-6 sm-12 mb-5 mono-container ${StandardHeroStyling.heroMainContainer}`
                : `col m-6 sm-12 mb-5 ${StandardHeroStyling.heroMainContainer}`
            }
          >
            {miniHeadline && (
              <p className="subtle mb-0 text6">{miniHeadline}</p>
            )}
            <h1 className={smallFontSize ? `mb-7 text48 ` : `mb-7 text8 `}>
              {widont(heroTitle)}
            </h1>
            {subtitleText.value && (
              <div className={StandardHeroStyling.heroSubtitle}>
                <StructuredTextRenderer data={subtitleText} />
              </div>
            )}
          </div>
        </div>
        {isActiveInPageNav && inPageLinks && (
          <InpageNav
            isInPageNavDropdown={isInPageNavDropdown}
            inPageLinks={inPageLinks}
            inPageTrackingName={inPageTrackingName}
          />
        )}
      </Section>
      <Section
        className={StandardHeroStyling.heroBottom}
        style={{ minHeight: `${heroImage.gatsbyImageData.height / 2}px` }}
      >
        <span className={StandardHeroStyling.before}></span>
        <span
          className={StandardHeroStyling.after}
          style={{ backgroundColor: `${backgroundColor}` }}
        ></span>
        <div className={`row ${StandardHeroStyling.heroBottomRow}`}>
          <div
            className={
              hideBgImgOnMobile
                ? `col m-5 sm-12 ${StandardHeroStyling.hideImageMobile} ${StandardHeroStyling.heroImageWrapper}`
                : `col m-5 sm-12 ${StandardHeroStyling.heroImageWrapper}`
            }
          >
            <GatsbyImage
              image={heroImage.gatsbyImageData}
              className={StandardHeroStyling.heroImage}
              alt={heroImage.alt}
            />
          </div>
          <div className="col c-1 hide-to-m"></div>
          <div className={`col m-6 sm-12 ${StandardHeroStyling.textContainer}`}>
            {text.value && (
              <StructuredTextRenderer data={text} isHeadingClassName="mb-5" />
            )}
          </div>
        </div>
      </Section>
    </section>
  );
};

export default StandardHero;

StandardHero.propTypes = {
  block: PropTypes.shape({
    backgroundColor: PropTypes.shape({
      hex: PropTypes.string.isRequired,
    }).isRequired,
    heroImage: PropTypes.object.isRequired,
    heroTitle: PropTypes.string.isRequired,
    subtitleText: PropTypes.object,
    text: PropTypes.object,
    activateInPageNavigation: PropTypes.bool.isRequired,
    inPageNavigationAsDropdown: PropTypes.bool,
    inPageTrackingName: PropTypes.string,
    inpagelinks: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        trackingName: PropTypes.string,
        target: PropTypes.string,
      })
    ),
  }),
};

export const query = graphql`
  fragment StandardHero on DatoCmsStandardhero {
    model {
      apiKey
    }
    id: originalId
    backgroundColor {
      hex
    }
    heroTitle
    subtitleText {
      value
      blocks {
        __typename
        ...Link
      }
    }
    activateInPageNavigation
    inPageNavigationAsDropdown
    inPageTrackingName
    inpagelinks {
      text
      trackingName
      target
    }
    heroImage {
      alt
      gatsbyImageData(imgixParams: { w: "488", h: "609", fit: "crop" })
    }
    hideBackgroundImageOnMobile
    text {
      value
      blocks {
        __typename
        ...List
      }
    }
  }
`;
