import React from "react";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import colors from "lib/colors";
import StandardHero from "components/blocks/Heros/StandardHero/StandardHero";
import Text from "components/blocks/Text/Text";
import Gallery from "components/Gallery/Gallery";

const GuidePage = ({ data }) => {
  const blocks = data.datoCmsGuidepage;
  const hero = blocks.hero[0];
  const seo = data.datoCmsGuidepage.seoMetaTags;
  const readingTime = data.datoCmsGuidepage.readingTime;
  const firstPublishedAt = blocks.meta.firstPublishedAt;
  const guides = data.guides.nodes;

  return (
    <>
      <HelmetDatoCms seo={seo} />
      <StandardHero block={hero} miniHeadline="Guide" smallFontSize={true} />
      {blocks.content.map((block, index) => {
        const hasDarkBackground =
          block.backgroundColor &&
          colors.isDarkColor(block.backgroundColor.hex);
        const backgroundColor =
          block.backgroundColor && block.backgroundColor.hex;

        return (
          <Text
            key={block.id}
            block={block}
            showMetaData={index === 0}
            firstPublishedAt={firstPublishedAt}
            readingTime={readingTime}
            backgroundColor={backgroundColor}
            hasDarkBackground={hasDarkBackground}
          />
        );
      })}
      <Gallery items={guides} title="Related articles" />
    </>
  );
};

export default GuidePage;

export const query = graphql`
  query ($title: String!) {
    datoCmsGuidepage(title: { eq: $title }) {
      id
      meta {
        firstPublishedAt
      }
      readingTime
      title
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      navbarColors
      hero {
        ...StandardHero
      }
      content {
        __typename
        ...on DatoCmsText {
          id: originalId
          model {
            apiKey
          }
          backgroundColor {
            hex
          }
          text {
            value
            blocks {
              __typename
              ...Quoteblock
              ...Link
              ...PublicationGrid
              ... on DatoCmsPlaylist {
                id: originalId
                title
                url
                description {
                  value
                  blocks {
                    __typename
                    ...Link
                  }
                }
                cover {
                  alt
                  gatsbyImageData
                }
              }
              ... on DatoCmsTablereference {
                id: originalId
                table {
                  visibleFor
                  tableDesign
                  table
                }
              }
              ...VideoPlayer
              ... on DatoCmsImage {
                id: originalId
                image {
                  gatsbyImageData(width: 800)
                  alt
                  title
                }
              }
            }
          }
        }
      }
    }
    guides: allDatoCmsGuidepage(
      filter: { title: { ne: $title } }
      limit: 6
      sort: { fields: meta___firstPublishedAt, order: DESC }
    ) {
      nodes {
        ...GuidePageGalleryItem
      }
    }
  }
`;
