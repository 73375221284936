import React from "react";
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";
import PropTypes from "prop-types";
import { date } from "lib/fns.js";
import * as TextStyling from "./Text.module.scss";

const Text = ({
  block,
  showMetaData,
  firstPublishedAt,
  readingTime,
  hasDarkBackground,
  backgroundColor,
}) => {
  const text = block.text;

  return (
    <Section
      style={{
        backgroundColor: backgroundColor ? backgroundColor : "transparent",
      }}
      className={`${TextStyling.textSection} ${hasDarkBackground ? "mono-container" : ""}`}
    >
      <div className="row">
        <div className="col sm-2"></div>
        <div className="col sm-8 ">
          {showMetaData && (
            <div className="mb-7">
              <p className={`${TextStyling.firstPublishedAt} mb-0`}>Posted on {date(firstPublishedAt)}</p>
              <p className="subtle">{readingTime} read</p>
            </div>
          )}
          <StructuredTextRenderer
            data={text}
            imageClassName={TextStyling.image}
            isHeadingClassName={TextStyling.heading}
            isTextContent={true}
          />
        </div>
        <div className="col sm-2"></div>
      </div>
    </Section>
  );
};

export default Text;
Text.propTypes = {
  block: PropTypes.shape({
    text: PropTypes.object.isRequired,
    backgroundColor: PropTypes.object,
  }).isRequired,
  showMetaData: PropTypes.bool,
  readingTime: PropTypes.string.isRequired,
  firstPublishedAt: PropTypes.string.isRequired,
};
